



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {Token} from '@/model/resource/Token'
import {InputTokenSchema} from '@/schema/resource/Token/InputTokenSchema'

@Component
export default class PersistTokenView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputTokenSchema()
  token = new Token()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.token.getToken(id)
    }

    this.$await.done('getToken')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.token.persistToken()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/token/list')
  }
}
